/* eslint-disable react/forbid-prop-types */
import React, { useCallback, useEffect } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import { FormHelperText } from '@mui/material'
import { InputLabel, MenuItem, Select as MuiSelect } from '@material-ui/core'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import selectStyles from './styles'
import generalInputStyles from '../styles'

/**
 * Renders a Select component with a label, options, and validation rules.
 * @param {Object} props - The props object.
 * @param {Object} props.menuProps - The props for the menu.
 * @param {Array} props.options - The array of options to be displayed in the select.
 * @param {string} props.label - The label for the select.
 * @param {string} props.name - The name of the select. (Hook-Form related)
 * @param {boolean} [props.required=false] - Whether the select is required or not.
 * @param {Function} props.getValue - The function to get the value of an option. (MenuItem related)
 * @param {Function} [props.getLabel=()=>{}] - The function to get the label of an option. (MenuItem related)
 * @param {Function} props.getKey - The function to get the key of an option. (MenuItem related. Each option must have a unique key)
 * @param {string} [props.defaultValue=''] - The default value of the select.
 * @param {boolean} [props.disabled=false] - Whether the select is disabled or not.
 * @param {Object} [props.style={}] - The style object for the select container.
 * @param {string} [props.containerClasses=null] - The classes for the select container.
 * @param {string} [props.labelClasses=null] - The classes for the select label.
 * @param {string} [props.selectClasses=null] - The classes for the Mui select component.
 * @returns {React.ReactElement} - The Select component.
 */
function Select({
  options,
  label,
  name,
  required = false,
  getValue,
  getLabel = () => {},
  getKey,
  defaultValue = '',
  disabled = false,
  style = {},
  containerClasses,
  labelClasses,
  selectClasses,
  ...rest
}) {
  const generalStyles = generalInputStyles()
  const localStyles = selectStyles()
  const { control, errors, setValue } = useFormContext()

  useEffect(() => {
    setValue(name, defaultValue)
  }, [defaultValue, name, setValue])

  const generateOptionKey = useCallback(
    item => {
      return getKey ? getKey(item) : crypto.randomUUID()
    },
    [getKey]
  )

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '80px',
        justifyContent: 'center',
        ...style,
      }}
      className={classNames(localStyles.select, containerClasses ?? null)}
    >
      <InputLabel
        variant="standard"
        id={`${name}-label`}
        error={!!errors[name]}
        style={{ color: errors[name] ? '#C90000' : null }}
        className={classNames(localStyles.selectHeightCompensation, labelClasses ?? null)}
        shrink
      >
        {label}
        {required && <span className={generalStyles.required}>*</span>}
      </InputLabel>
      <Controller
        control={control}
        name={name}
        defaultValue={defaultValue ?? ''}
        rules={{ required: { value: required, message: 'Esse campo é obrigatório' } }}
        as={
          <MuiSelect
            MenuProps={{
              getContentAnchorEl: null,
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'center',
              },
            }}
            displayEmpty
            labelId={`${name}-label`}
            required={required}
            disabled={disabled}
            error={!!errors[name]}
            className={selectClasses ?? null}
            {...rest}
          >
            {options?.length ? (
              options.map(item => (
                <MenuItem key={generateOptionKey(item)} value={getValue(item)}>
                  {getLabel(item) || getValue(item)}
                </MenuItem>
              ))
            ) : (
              <MenuItem value="" disabled>
                Nenhum item encontrado
              </MenuItem>
            )}
          </MuiSelect>
        }
      />
      <FormHelperText className={localStyles.errorMessage} error={!!errors[name]}>
        {errors[name]?.message}
      </FormHelperText>
    </div>
  )
}

Select.propTypes = {
  menuProps: PropTypes.object.isRequired,
  options: PropTypes.array.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
  getValue: PropTypes.func.isRequired,
  getLabel: PropTypes.func,
  getKey: PropTypes.func.isRequired,
  defaultValue: PropTypes.string,
  disabled: PropTypes.bool,
  style: PropTypes.object,
  containerClasses: PropTypes.string,
  labelClasses: PropTypes.string,
  selectClasses: PropTypes.string,
}

Select.defaultProps = {
  required: false,
  defaultValue: '',
  disabled: false,
  style: {},
  containerClasses: null,
  labelClasses: null,
  selectClasses: null,
  getLabel: () => {},
}

export default Select
